.signupPopupContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 100;
}

.bgOverlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: #000000;
  opacity: 0.8;
}

.signupPopup {
  width: 862px;
  background: linear-gradient(109.62deg, #cde09c 0%, #9bb361 100%);
  padding: 70px 80px;
  border-radius: 30px;
  position: relative;
}

.signupPopup .crossIconCont {
  padding: 6px;
  position: absolute;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.signupPopup .crossIconCont svg {
  width: 25px;
  height: 25px;
}

.signupPopup .heading h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
}

.signupPopup .inputFields .input,
.signupPopup .inputFields .emailinput {
  margin-top: 30px;
}

.signupPopup .inputFields .input input,
.signupPopup .inputFields .emailinput input {
  background: #ffffff;
  width: 100%;
  border-radius: 13px;
  padding: 19px 70px;

  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #000000;

  outline: 0px;
  border: 0px;
}

.signupPopup .inputFields .emailinput input {
  text-transform: lowercase;
}

.signupPopup .inputFields .input input::placeholder {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #000000;
  opacity: 0.7;
}

.submitBtn {
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.submitBtn button,
.submitBtn input[type="submit"] {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  padding: 19px 124px;
  background: #ffffff;
  border-radius: 13px;
  cursor: pointer;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  grid-gap: 20px;
  align-items: center;
}

/* signup response */
.signupResponse .imageCont {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.signupResponse .heading h2 {
  margin-bottom: 16px;
}

.signupResponse .description p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  opacity: 0.7;
}

.errorResponse {
  height: 70px;
}

.errorResponse p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ff0000;
  opacity: 0.7;
}

@media screen and (max-width: 640px) {
  .signupPopup {
    height: 100%;
    padding: 50px 20px;
    border-radius: 0px;
  }

  .signupPopup .crossIconCont {
    right: 20px;
    top: 20px;
  }

  .signupPopup .heading h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }

  .signupPopup .inputFields .input input,
  .signupPopup .inputFields .emailinput input {
    border-radius: 5px;
    padding: 12px 40px;
    font-weight: 600;
    font-size: 16px;
  }

  .signupPopup .inputFields .input input::placeholder,
  .signupPopup .inputFields .emailinput input::placeholder {
    font-weight: 600;
    font-size: 16px;
  }

  .submitBtn button {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 40px;
  }

  .signupResponse .description p {
    font-size: 16px;
    line-height: 22px;
  }
}
