.wrapper {
}

.wrapperInner {
  width: 100%;
  max-width: 1600px;
  padding: 83px 40px;
  margin: auto;
}

.h2 h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .wrapperInner {
    padding: 40px 20px 50px 20px;
  }
}

@media screen and (max-width: 640px) {
  .wrapperInner {
    padding: 20px 10px 30px 10px;
  }

  .h2 {
    display: flex;
    justify-content: center;
  }

  .h2 h2 {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 30px;
    max-width: 220px;
  }
}
