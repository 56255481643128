.wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.wrapperInner {
  width: 100%;
  max-width: 1400px;
  padding: 0px 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.leftPart {
  height: 100%;
  display: flex;
  align-content: center;
}

.leftPart .logoContainer {
  align-self: center;
}

.leftPart .logoContainer img {
  width: 100%;
  max-width: 333px;
}

.rightPart {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.nav ul li {
  margin-bottom: 40px;
}

.nav ul li a {
  text-decoration: none;
  padding: 6px 16px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #5db89d;
  display: inline-block;
  padding-left: 0px;
}

.nav ul li a.current {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
  text-underline-offset: 3px;
}

.toggleIconCont {
  display: flex;
  cursor: pointer;
}

.toggleIconCont svg {
  height: 37px;
  width: 32px;
}

/* hamburger */

.hamburger {
  position: fixed;
  right: 0px;
  left: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 40px;
  z-index: 10;
}

.hamburger .crossIconCont {
  width: 100%;
  height: 90px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
}

.hamburger .crossIconCont svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .wrapperInner {
    padding: 0px 27px;
  }
}

@media screen and (max-width: 640px) {
  .wrapper {
    height: 60px;
  }

  .wrapperInner {
    padding: 0px 10px;
  }

  .hamburger {
    padding: 0px 20px;
  }

  .leftPart .logoContainer img {
    max-width: 170px;
  }

  .hamburger .crossIconCont {
    height: 60px;
  }

  .toggleIconCont svg {
    height: 24px;
    width: 22px;
  }
}
