.wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.wrapperInner {
  width: 100%;
  max-width: 1600px;
  padding: 0px 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.leftPart {
  height: 100%;
  display: flex;
  align-content: center;
}

.leftPart .logoContainer {
  align-self: center;
}

.leftPart .logoContainer img {
  width: 100%;
  max-width: 368px;
}

.rightPart {
  height: 100%;
}

.rightPart .nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.rightPart .nav ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 20px;
}

.rightPart .nav ul li a {
  text-decoration: none;
  padding: 6px 16px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #5db89d;
  display: inline-block;
  padding-left: 0px;
}

.rightPart .nav ul li a.current {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
  text-underline-offset: 3px;
}

@media screen and (max-width: 640px) {
  .wrapperInner {
    padding: 0px 10px;
  }
}
