.wrapper {
}

.wrapperInner {
  width: 100%;
  max-width: 1600px;
  padding: 0px 40px;
  margin: auto;
}

.h2 h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  color: #ffffff;
  text-align: center;
}

.txtImage {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.txtImage .txt {
  margin-top: 20px;
  z-index: 1;
  position: relative;
}

.txtImage .txt p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 122.4%;
  /* or 39px */

  color: #ffffff;
}

.block1 {
  height: 800px;
  background: linear-gradient(109.62deg, #cde09c 0%, #9bb361 100%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
}

.block1 .txtImage {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.block1 .txt {
  max-width: 712px;
}

.block1 .img {
  position: absolute;
  left: 0px;
  bottom: 0px;
  max-width: 457px;
}

.block1 .img img {
  width: 100%;
}

.block1 h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  color: #ffffff;
  margin-bottom: 56px;
}

/* block2 */
.block2 {
  height: 800px;
  background: linear-gradient(109.62deg, #79c8c6 0%, #329a98 100%);
  border-radius: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.block2 .txtImage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.block2 .txt {
  max-width: 651px;
}

.block2 .img {
  position: absolute;
  bottom: -4px;
  right: -10px;
  max-width: 635px;
}

.block2 .img img {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .block2 .img {
    max-width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .wrapperInner {
    padding: 0px 20px;
  }

  .h2 {
    display: flex;
    justify-content: center;
  }

  .h2 h2 {
    font-size: 58px;
    line-height: 62px;
    max-width: 550px;
  }

  .txtImage .txt p {
    font-size: 24px;
    line-height: 36px;
  }

  /* block1 */
  .block1 {
    padding: 48px 25px;
  }

  .block1 .txt {
    max-width: 500px;
  }

  .block1 .img {
    max-width: 220px;
    top: 40%;
  }

  .block1 h3 {
    font-size: 40px;
    line-height: 47px;
  }

  /* block2 */

  .block2 {
    padding: 48px 25px;
  }

  .block2 .txtImage {
    align-items: flex-start;
  }

  .block2 .txt {
    margin-top: 40px;
    max-width: none;
  }
}

@media screen and (max-width: 640px) {
  .wrapperInner {
    padding: 0px 10px;
  }

  .h2 {
    display: flex;
    justify-content: center;
  }

  .h2 h2 {
    font-size: 24px;
    line-height: 28px;
    max-width: 300px;
  }

  .txtImage .txt p {
    font-size: 16px;
    line-height: 26px;
  }

  .txtImage .txt {
    max-width: none;
  }

  /* block1 */
  .block1 {
    padding: 24px 15px;
    border-radius: 20px;
    height: auto;
    margin-bottom: 16px;
  }

  .block1 .txtImage {
    align-items: flex-start;
    flex-flow: column nowrap;
  }

  .block1 .img {
    max-width: 150px;

    position: relative;
    left: -14px;
    top: 32px;
  }

  .block1 h3 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  /* block2 */
  .block2 {
    padding: 24px 15px;
    border-radius: 20px;

    margin-bottom: 16px;
    height: auto;
  }

  .block2 .txtImage {
    align-items: flex-end;
    flex-flow: column nowrap;
  }

  .block2 .img {
    max-width: 250px;
    position: relative;
    bottom: -28px;
    right: -18px;
  }
}
