.wrapper {
}

.wrapperInner {
  width: 100%;
  max-width: 1600px;
  height: 800px;
  margin: auto;
  background-image: url("/public/images/mask-group.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 40px;
}

.wrapperInner img {
  width: 100%;
  opacity: 0;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 640px) {
  .wrapperInner {
    height: 400px;
    margin-bottom: 16px;
  }
}
