.wrapper {
  max-width: 1600px;
  margin-bottom: 40px;
  margin: auto;
  padding: 0px 40px 16px 40px;
}

.wrapperInner {
  background: linear-gradient(112.36deg, #c0d884 2.44%, #44a6a4 75.89%);
  border-radius: 40px;
  width: 100%;
  padding: 86px 112px;
  margin: auto;
}

.wrapperInner h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 85px;
  color: #ffffff;
}

.wrapperInner h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
}

.wrapperInner h4 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  color: #ffffff;
}

.wrapperInner h5 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  color: #ffffff;
}

.wrapperInner p,
.wrapperInner a {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */

  color: #ffffff;
  margin: 45px 0px;
}

.faqParagraphPadding {
  padding-bottom: 40px;
}

.wrapperInner ol,
.wrapperInner ul {
  margin: 45px 0px 45px 50px;
}

.wrapperInner ol li,
.wrapperInner ul li {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */

  color: #ffffff;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .wrapperInner {
    padding: 40px 52px;
  }
}

@media screen and (max-width: 640px) {
  .wrapper {
    padding: 0px 10px 10px 10px;
  }

  .wrapperInner {
    padding: 24px;
    border-radius: 20px;
  }

  .wrapperInner h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .wrapperInner h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
  }

  .wrapperInner h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }

  .wrapperInner h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  .wrapperInner p,
  .wrapperInner a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 12px 0px;
  }

  .wrapperInner ol,
  .wrapperInner ul {
    margin: 20px 0px 20px 40px;
  }

  .wrapperInner ol li,
  .wrapperInner ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 12px 0px;
  }
}
