.wrapper {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
}

.wrapperInner {
  width: 100%;
  max-width: 1400px;
  padding: 40px 40px;
  margin: auto;
}

.footerBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  grid-gap: 20px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr 1fr;
}

.footerBlock .h3 {
  margin-bottom: 40px;
}

.footerBlock .h3 h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}

.footerBlock p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin: 24px 0px;
}

.footerBlock a {
  text-decoration: none;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin: 24px 0px;
}

.footerBlock .block1 {
}

.footerBlock .block1 .logoCont img {
  max-width: 368px;
}

.footerBlock .block2 .legalBlock {
}

.footerBlock .block23 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.footerBlock .block3 .contactUsBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  grid-gap: 15px;
}

.footerBlock .block3 .phoneAndAddressBlock {
  max-width: 300px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin: 24px 0px;
}

.footerBlock .block3 .contactUsBlock img {
  max-width: 22px;
  cursor: pointer;
}

.footerBlock .block4 {
}

.rightsReserved p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 1024px) {
  .footerBlock .h3 {
    margin-bottom: 20px;
  }

  .footerBlock .block1 .logoCont img {
    max-width: 230px;
  }
}

@media screen and (max-width: 840px) {
  .footerBlock {
    display: flex;
    grid-gap: 40px;
    justify-content: flex-start;
  }

  .footerBlock .block1 {
    width: 100%;
  }

  .footerBlock .block23 {
    grid-gap: 40px;
  }
}

@media screen and (max-width: 768px) {
  .footerBlock .block23 {
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 640px) {
  .footerBlock {
    display: flex;
  }
  .footerBlock .block23 {
    justify-content: space-between;
    width: 100%;
  }
  .footerBlock .h3 {
    margin-bottom: 18px;
  }
  .footerBlock .block1 .logoCont img {
    max-width: 160px;
  }

  .footerBlock {
    grid-gap: 24px;
  }
  .footerBlock .h3 h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
  }

  .footerBlock p,
  .footerBlock a {
    margin: 12px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .rightsReserved p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .footerBlock .block3 .contactUsBlock {
    grid-gap: 5px;
  }
}
