.wrapper {
}

.wrapperInner {
  width: 100%;
  max-width: 1600px;
  padding: 0px 40px;
  margin: auto;
  overflow: hidden;
}

.h2 h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 85px;
  text-align: center;
  color: #ffffff;
}

.txtImage {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.txtImage .txt {
  margin-top: 0px;
  position: relative;
  z-index: 1;
}

.txtImage .txt p,
.txt p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;

  color: #ffffff;
}

.txtImage .txt li {
  color: #ffffff;
  margin-bottom: 53px;
}

/* block1 */

.block1 {
  height: 800px;
  background: linear-gradient(112.36deg, #c0d884 2.44%, #44a6a4 75.89%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

.block1 .txtImage {
  display: flex;
}

.block1 .img {
  position: absolute;
  right: -50px;
  bottom: -4px;
  max-width: 851px;
}

.block1 .img img {
  width: 100%;
}

.block1 .txt {
  max-width: 620px;
}

.block1 .h2 {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  justify-content: center;
}

.block1 .h2 h2 {
  text-align: center;
  max-width: 500px;
}

.signupBtnCont {
  margin-top: 32px;
  text-align: center;
}

.txtBlocks {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.revolutionP {
  margin-top: 101px !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  left: -20px;
  position: relative;
}

.revolutionP p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #ffffff;
}

.marketeP {
  max-width: 405px;
  text-align: center;
}

/* block2 */

.block2 {
  height: 800px;
  background: linear-gradient(
    109.62deg,
    #44a6a4 0%,
    #79c8c6 0.01%,
    #329a98 99.26%
  );
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

.block2 .txtImage {
  align-items: flex-end;
  justify-content: center;
}

.block2 .masksGroup {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: center;
}

.block2 .masksGroup .maskImage {
  position: absolute;
  bottom: -52px;
  width: 100px;
}

.block2 .masksGroup .maskImage img {
  width: 100%;
}

.block2 .masksGroup .maskImage1 {
  transform: translateX(-51px);
}

.block2 .masksGroup .maskImage2 {
  transform: translateX(-29px);
}

.block2 .masksGroup .maskImage3 {
  z-index: 10;
}

.block2 .masksGroup .maskImage4 {
  transform: translateX(29px);
  z-index: 1;
}

.block2 .masksGroup .maskImage5 {
  transform: translateX(51px);
}

/* block3 */

.block3 {
  height: 800px;
  background: linear-gradient(109.62deg, #cde09c 0%, #9bb361 100%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

.block3 .h2 {
  justify-content: left;
}

.block3 .h2 h2 {
  text-align: left;
}

.block3 .txt {
  max-width: 650px;
}

.block3 .txt p {
  margin-bottom: 48px;
}

.block3 .img {
  width: 500px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
}

.block3 .img img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.block3 .img img.img1 {
  position: absolute;
  right: -50px;
  bottom: -30px;
  width: 576px;
  animation-name: block3Img1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  opacity: 0;
}

.block3 .img img.img2 {
  position: absolute;
  width: 387px;
  animation-name: block3Img2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  opacity: 0;
}

@keyframes block3Img1 {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes block3Img2 {
  0% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }

  52% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.planetP {
}

/* block4 */
.block4 {
  height: 800px;
  background: linear-gradient(109.62deg, #79c8c6 0%, #329a98 100%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

.block4 .txtImage {
  display: flex;
  flex-flow: row-reverse nowrap;
}

.block4 .txt {
  max-width: 640px;
}

.block4 .txt2 p {
  margin-top: 15px;
  text-align: center;
}

/*
.block4 .img {
  position: absolute;
  left: 0px;
  bottom: -50px;
  max-width: 400px;
}

.block4 .img img {
  width: 100%;
}
*/

.block4 .img {
  width: 400px;
  height: 100%;
  position: absolute;
  left: 0px;
  bottom: -50px;
  display: flex;
}

.block4 .img img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.block4 .img img.img1 {
  left: 0px;
  bottom: -13px;
  width: 494px;
  animation-name: block4Img1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  opacity: 0;
}

.block4 .img img.img2 {
  width: 504px;
  left: 0px;
  bottom: -21px;
  animation-name: block4Img2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  opacity: 0;
}

@keyframes block4Img1 {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes block4Img2 {
  0% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }

  52% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* block5 */
.block5 {
  height: 800px;
  background: linear-gradient(109.62deg, #cde09c 0%, #9bb361 100%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

.block5 .img {
  position: absolute;
  right: 0px;
  bottom: -4px;
  max-width: 743px;
}

.block5 .img img {
  width: 100%;
}

.block5 .txtImage {
  flex-flow: column nowrap;
  text-align: center;
}

.block5 .txt {
  max-width: 891px;
}

/* block6 */
.block6 {
  height: auto;
  background: linear-gradient(109.62deg, #79c8c6 0%, #329a98 100%);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 48px;
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 20px;
  overflow: hidden;
}

.block6 .txtImage {
  align-items: flex-start;
  justify-content: center;
  grid-gap: 200px;
}

.block6 .txt {
  max-width: 610px;
}

.numberHeading {
  display: flex;
  grid-gap: 20px;
}

.block6 .img {
  overflow: hidden;
  position: sticky;
  top: 0px;
}

.block6 .img .img1,
.block6 .img .img2,
.block6 .img .img3,
.block6 .img .img4,
.block6 .img .img5,
.block6 .img .img6 {
  overflow: hidden;
  margin-top: 50px;
}

.block6 .img .img1,
.block6 .img .img1 img {
  position: relative;
  opacity: 1;
  width: 100%;
}

.txt .listing {
  position: relative;
  height: 800px;
}

.txt .listing1,
.txt .listing2,
.txt .listing3,
.txt .listing4 {
  display: flex;
  /*position: absolute;*/
  top: 0px;
  grid-gap: 10px;
  opacity: 1;
}

.block6 .img .img0 {
  position: relative;
}

.swapCycleContainer {
  margin-top: 50px;

  /*
  scroll-snap-type: y;
  overflow: hidden auto;
  */
}

.swapCycle {
  /*
  scroll-snap-align: start;
  display: block;
  */
}

.block6Anim .txt .listing1 {
  animation-name: block6Txt1Anim;
  animation-duration: 16s;
  animation-delay: 0s;
  transform: translateY(-700px);
}

.block6Anim .img .img1 {
  animation-name: block6Img1Anim;
  animation-duration: 8s;
  animation-delay: 0;
  transform: translateX(-700px);
}

.block6Anim .img .img2 {
  animation-name: block6Img1Anim;
  animation-duration: 8s;
  animation-delay: 8s;
  transform: translateX(-700px);
}

.block6Anim .txt .listing2 {
  animation-name: block6Txt1Anim;
  animation-duration: 8s;
  animation-delay: 16s;
  transform: translateY(-700px);
}

.block6Anim .img .img3 {
  animation-name: block6Img1Anim;
  animation-duration: 8s;
  animation-delay: 16s;
  transform: translateX(-700px);
}

.block6Anim .txt .listing3 {
  animation-name: block6Txt1Anim;
  animation-duration: 8s;
  animation-delay: 24s;
  transform: translateY(-700px);
}

.block6Anim .img .img4 {
  animation-name: block6Img1Anim;
  animation-duration: 8s;
  animation-delay: 24s;
  transform: translateX(-700px);
}

.block6Anim .txt .listing4 {
  animation-name: block6Txt1Anim;
  animation-duration: 60s;
  animation-delay: 32s;
  transform: translateY(-700px);
}

.block6Anim .img .img5 {
  animation-name: block6Img1Anim;
  animation-duration: 60s;
  animation-delay: 32s;
  transform: translateX(0px);
  opacity: 0;
}

@keyframes block6Img1Anim {
  0% {
    opacity: 0;
    z-index: 1;
    transform: translateX(700px);
  }
  5% {
    opacity: 1;
    transform: translateX(0);
  }

  80% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    transform: translateX(-700px);
    opacity: 1;
    z-index: 0;
  }
}

@keyframes block6Txt1Anim {
  0% {
    opacity: 0;
    z-index: 1;
    transform: translateY(700px);
  }
  5% {
    opacity: 1;
    transform: translateX(0);
  }

  80% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    transform: translateY(-700px);
    opacity: 1;
    z-index: 0;
  }
}

.listingNumber h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: #ffffff;
}

.listingHeading {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 75px;
  color: #ffffff;
}

.listingDescription {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  margin-top: 40px;
}

@media screen and (max-width: 1280px) {
  .block3 .txt {
    max-width: 450px;
  }
  .block4 .txt {
    max-width: 550px;
  }

  .block1 .img {
    max-width: 627px;
  }
  .block4 .img {
    max-width: 360px;
  }

  .block6 .txtImage {
    grid-gap: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .wrapperInner {
    padding: 0px 27px;
  }

  .h2 {
    display: flex;
    justify-content: center;
  }

  .h2 h2 {
    font-size: 64px;
    line-height: 75px;
  }

  .txtImage .txt p,
  .txt p {
    font-size: 32px;
    line-height: 38px;
  }

  .txtImage .txt li {
    margin-bottom: 20px;
  }

  /* block1 */
  .block1 {
    padding: 48px 25px;
  }

  .block1 .h2 {
    top: 0px;
    margin: 0px;
  }

  .block1 .txtImage {
    align-items: flex-start;
  }

  .block1 .txt {
    max-width: 600px;
    margin-top: 0px;
  }

  .revolutionP {
    font-size: 28px !important;
    margin-top: 0px !important;
    font-weight: bold !important;
  }

  .signupBtnCont {
    margin-top: 53px;
    text-align: center;
  }

  .marketeP {
    max-width: 400px;
    margin: 53px 0px;
  }

  /* block2 */

  .block2 {
    padding: 48px 25px;
  }

  /* block3 */
  .block3 {
    padding: 48px 25px;
  }

  .block3 .img {
    max-width: 400px;
    bottom: -22px;
  }

  .block3 .img img.img1 {
    width: 516px;
    right: -17px;
    bottom: -2px;
  }

  .block3 .img img.img2 {
    width: 330px;
    right: 14px;
    bottom: 22px;
  }

  /* block4 */
  .block4 {
    padding: 48px 25px;
  }

  .block4 .txtImage {
    display: flex;
    flex-flow: row-reverse nowrap;
  }

  .block4 .img {
    position: absolute;
    left: 0px;
    bottom: -40px;
    max-width: 300px;
  }

  .block4 .img img.img1 {
    left: 0px;
    bottom: -13px;
    width: 316px;
  }

  .block4 .img img.img2 {
    width: 316px;
    left: 0px;
    bottom: 13px;
  }

  .block4 .txt {
    max-width: 450px;
  }

  /* block5 */

  .block5 .img {
    max-width: 700px;
  }

  /* block6 */

  .block6 {
    padding: 48px 25px;
  }

  .block6 h2 {
    font-size: 56px;
    line-height: 66px;
  }

  .block6 .txt {
    max-width: 500px;
  }

  .block6 .txtImage {
    grid-gap: 20px;
  }

  .txt .listing {
    height: 700px;
  }

  .listingNumber h3 {
    font-size: 56px;
    line-height: 68px;
  }

  .listingHeading {
    font-size: 56px;
    line-height: 68px;
  }
}

@media screen and (max-width: 780px) {
  .block4 .txtImage {
    flex-flow: column nowrap;
  }

  .block4 .txt {
    max-width: none;
    margin-left: 30px;
  }
}

@media screen and (max-width: 640px) {
  .wrapperInner {
    padding: 0px 16px;
  }

  .h2 {
    display: flex;
    justify-content: center;
  }

  .h2 h2 {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .txtImage .txt p,
  .txt p {
    font-size: 16px;
    line-height: 26px;
  }

  .txtImage .txt {
    max-width: none;
    margin-top: 16px;
  }

  .txtImage .txt li {
    margin-bottom: 30px;
  }

  /* block1 */
  .block1 {
    padding: 24px 15px;
    height: auto;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .block1 .h2 {
    justify-content: center;
    margin: 0px;
  }

  .block1 .txtImage {
    align-items: center;
    flex-flow: column nowrap;
  }

  .block1 .h2 h2 {
    margin: 0px;
    max-width: 220px;
    font-size: 30px;
    font-weight: 900;
    line-height: 37px;
  }

  .block1 .txt {
    margin-top: 0px;
  }

  .block1 .img {
    position: relative;
    right: 0px;
    bottom: -28px;
    width: 120%;
  }

  .revolutionP {
    text-align: center;
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 23px !important;
    width: 220px;
    margin: auto;
    left: 0px;
  }

  .marketeP {
    margin: 24px 0px;
    font-size: 17px !important;
    line-height: 19px;
    width: 200px;
  }

  .signupBtnCont {
    margin-top: 24px;
  }

  /* block2 */
  .block2 {
    padding: 24px 15px;
    height: 500px;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .block2 .txt {
    margin-top: 20px;
  }

  .block2 .img img {
    max-width: 250px;
  }

  .block2 .masksGroup {
    margin-bottom: 24px;
  }

  /* block3 */

  .block3 {
    padding: 24px 15px;
    height: auto;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .block3 .txtImage {
    align-items: center;
    flex-flow: column nowrap;
  }

  .block3 .txt p {
    margin-bottom: 14px;
  }

  .block3 .img {
    position: relative;
    max-width: 180px;
    bottom: -37px;
    right: 0px;
    margin-top: 20px;
  }

  .block3 .img img.img1 {
    width: 213px;
    right: -17px;
    bottom: 0px;
    position: relative;
  }

  .block3 .img img.img2 {
    width: 156px;
    right: 14px;
    bottom: 12px;
  }

  /* block4 */

  .block4 {
    padding: 24px 15px;
    height: auto;
    border-radius: 20px;
    margin-bottom: 16px;
  }
  .block4 .txt2 p {
    font-size: 20px;
    text-align: center;
  }

  .block4 .txtImage {
    align-items: flex-start;
  }

  .block4 .img {
    max-width: 200px;
    position: relative;
    bottom: -53px;
    left: -15px;
  }

  .block4 .img {
    position: relative;
    margin-top: -100px;
  }

  .block4 .img img.img1 {
    left: 0px;
    bottom: -13px;
    width: 237px;
    position: relative;
  }

  .block4 .img img.img2 {
    width: 237px;
    left: 0px;
    bottom: 13px;
  }

  /* block5 */

  .block5 {
    padding: 24px 15px;
    height: auto;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .block5 .img {
    position: relative;
    bottom: -28px;
    height: 300px;
    width: 100%;
  }

  .block5 .img img {
    width: 100%;
    position: absolute;
    right: -15px;
    bottom: 4px;
  }

  /* block6 */

  .block6 {
    padding: 24px 15px;
    height: auto;
    grid-gap: 0px;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .block6 .txtImage {
    flex-flow: column nowrap;
  }

  .block6 .img {
    max-width: 140px;
    position: relative;
    margin: auto;
    margin-top: 20px;
  }

  .txt .listing {
    height: auto;
  }

  .listingNumber h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .listingHeading {
    font-size: 24px;
    line-height: 28px;
  }

  .listingDescription {
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
  }
}
