:root {
  --primary-font-family: "Raleway", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Remove padding and margin */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

p {
  font-size: 1.2em;
}

.width-full {
  width: 100%;
}
