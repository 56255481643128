.signupBtn {
  background: #121212;
  border-radius: 58px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  color: #f9f9f9;
  padding: 19px 75px;
  cursor: pointer;
}

.androidBtn {
  margin-top: 15px;
  margin-right: 15px;
}

.iosBtn {
  margin-top: 15px;
  margin-right: 15px;
}

.beta {
  background: linear-gradient(91.82deg, #c0d884 55.77%, #5db89d 100%),
    linear-gradient(0deg, #f9f9f9, #f9f9f9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media screen and (max-width: 1024px) {
  .signupBtn {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #f9f9f9;
  }
}

@media screen and (max-width: 640px) {
  .signupBtn {
    padding: 8px 28px;
    font-size: 24px;
    line-height: 28px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .iosBtn,
  .androidBtn {
    margin: 5px;
    width: 100%; /* Buttons will take up full width on small screens */
  }
}
